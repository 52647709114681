var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Immigration Request")]),_c('br'),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.newImmigrationRequest.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Immigration Department")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.department.immigration_dept_name.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.departments},model:{value:(_vm.department.immigration_dept_name),callback:function ($$v) {_vm.$set(_vm.department, "immigration_dept_name", $$v)},expression:"department.immigration_dept_name"}}),(
                            _vm.submitted &&
                            _vm.$v.department.immigration_dept_name.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.department.immigration_dept_name
                                    .required
                            )?_c('span',[_vm._v("Department is required")]):_vm._e()]):_vm._e()],1)])]),_c('submitButton')],1),_c('paymentModal',{ref:"paymentWindow",attrs:{"requestId":_vm.requestId,"referenceNumber":_vm.refernceNumber,"page":"Immigration","modalTitle":_vm.modalTitle,"adminFees":_vm.adminFees,"urgentFees":_vm.urgentFees,"adminVatFees":_vm.adminVatFees}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }