<template>
    <Layout>
        <h4>New Immigration Request</h4>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="newImmigrationRequest"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Immigration Department</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="department.immigration_dept_name"
                            :options="departments"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.department.immigration_dept_name.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="
                                submitted &&
                                $v.department.immigration_dept_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.department.immigration_dept_name
                                        .required
                                "
                                >Department is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-3 mb-3">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div> -->
            <submitButton />
        </form>
        <paymentModal
            ref="paymentWindow"
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            page="Immigration"
            :modalTitle="modalTitle"
            :adminFees="adminFees"
            :urgentFees="urgentFees"
            :adminVatFees="adminVatFees"
        />
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */
import Layout from "../../layouts/main.vue";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import alertMixin from "../../../mixins/alertMixin";
import paymentModal from "../payment/paymentModal.vue";
import submitButton from "../../../components/submitButton.vue";
export default {
    mixins: [alertMixin],
    components: {
        Layout,
        paymentModal,
        submitButton,
    },
    validations: {
        department: {
            immigration_dept_name: { required },
        },
    },
    data() {
        return {
            testData: "",

            submitted: false,
            fullPage: false,
            isUrgent: false,
            requestId: "",
            refernceNumber: "",
            adminFees: "",
            adminVatFees: "",
            urgentFees: "",
            modalTitle: "",
            department: {
                immigration_dept_name: "",
            },
            departments: [
                { value: null, text: "Choose department" },
                {
                    value: "30",
                    text: "Department of Naturalisation and Residency Dubai (DNRD)",
                },
            ],
        };
    },
    mounted() {
        this.getTermsConditions();
    },
    methods: {
        proceed(e) {
            console.log("payment initiated");
        },
        changeStatus() {
            this.isUrgent = !this.isUrgent;
        },
        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES[4].id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.testData = res.data.response.service_fees;
                        this.adminFees = res.data.response.service_fees;
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                    });
            }
        },
        newImmigrationRequest() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}immigrations?token=${localStorage.cs_user_token}`,
                            this.department
                        )
                        .then((res) => {
                            // this.resetFields();
                            console.log(res.data.response);
                            this.requestId = res.data.response.request_id;
                            this.refernceNumber = res.data.response.ref_no;
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            this.$refs.paymentWindow.showModal();
                        })
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
    },
};
</script>
<style scoped></style>
